import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import '../App.css';
import axios from 'axios';
import { PAYMENTS_TO_REVERSE, REVERSE, REVERSED_PAYMENTS, generalGetMethod } from './api-calls/requests';
import { useLocation } from 'react-router-dom';
import { searchArray } from './helpers';


const ReversedPayments = () => {

  const location = useLocation()

  const event_info = location.state || null;

  const [reversal_list, setreversal_list] = useState([]);
  const [filteredList, setfilteredList] = useState([]);
  const [originalList, setoriginalList] = useState([]);
  const [query, setquery] = useState("");
  const [eventOnSale, seteventOnSale] = useState(false);
  const [errMsg, seterrMsg] = useState(null)

const getList = ()=>{

    const response = generalGetMethod(REVERSED_PAYMENTS,{"event_id":event_info.event_id}).then(
      (response) => { 
        if (!response.status) {
          seterrMsg("Beklemeyen bir hata oluştu!");
        }else if (response?.status===200){          
          if (response.data.reversed_list.length>0) {
            setfilteredList(response?.data.reversed_list)
            setoriginalList(response?.data.reversed_list)
          }else{
            seterrMsg("Bu etkinlikte iade edilen ödeme daha olmamış!")
          }
        }else{
          seterrMsg("Beklemeyen bir hata oluştu!")
        }

       }
    ).catch();
}

//handle payment reversal

const handlePaymentReversal = (event) => {
  event.preventDefault();
  generalGetMethod(REVERSE,
    {'event_id':event_info.event_id}).then(
      (response) => { 
        if (response?.status===200) {
          alert("İade edildi");
          //reload iade list to make it empty
          getList();
        }
       })
    }

  //handle search through list

  const handleSearch = (event) => {
    seterrMsg("");
    const query = event.target.value;
    const results = searchArray(originalList, query);

    setfilteredList(results);
    if (results.length === 0) {
      seterrMsg("Aranan kriterde kayıt bulunamadı");
    }
  }

useEffect(() => { 
  seterrMsg(null);  
  getList()
 },[]
);


  return (
    <div className="mt-[3rem] px-3">
      <h3 className='text-white '>İade Edilen Listesi</h3>
      <div className='flex justify-start my-3 pt-3  text-white'>
      
      <input
          type="text"
          placeholder="Arama..."
          value={query}
             onChange={(event) => {setquery(event.target.value); handleSearch(event);}}
            className='rounded-md bg-white px-3 text-black'
      />
      </div>
      {errMsg?
       <p className='text-red-500'>{errMsg}</p>

       :
      
      <Table striped bordered hover className=' table'>
        <thead>
          <tr>
            <th>Kullanici ID</th>
            <th>Ad</th>
            <th>Soyad</th>
            <th>Mail Addressi</th>
            <th>Ödeme Miktari</th>
            <th className=''>Eylemler</th>
          </tr>
        </thead>
        <tbody>
          {filteredList.map((item, index) => (
            <tr key={index}>
              <td className=''>{item.user_id}</td>
              <td>{item.first_name}</td>
              <td>{item.last_name}</td>
              <td>{item.email}</td>
              <td>{item.total_amount}</td>
              <td>
                <span  className="rounded-md cursor-pointer px-3 bg-red-400">
                  Iade Et
                </span>
               </td>
                
            </tr>
          ))}
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td> <td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td> <td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td> <td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
          <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td>
          </tr>
        </tbody>
      </Table>
      }
    </div>
  );
};

export default ReversedPayments;
