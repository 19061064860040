import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import '../../App.css';
import {generalGetMethod, ALL_EVENTS_URL, createQrs, sendQrs, REQUESTED_EVENTS_URL } from '../api-calls/requests';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';


const RequestedEvents = () => {


const navigateThere = useNavigate();

const [requestedEvents, setrequestedEvents] = useState([]);
const [errMsg, seterrMsg] = useState(null);




useEffect(
()=>{
  generalGetMethod(REQUESTED_EVENTS_URL).then((response)=>{
    if (!response.status){
      seterrMsg("Beklemeyen bir hata oluştu!")
    }else if (response.status===200){
      setrequestedEvents(response.data.requested_events)
    }else{
      seterrMsg("Beklemeyen bir hata oluştu!")
    }
    
    })
  },[]
  );



  return (
    <div className='mt-[5rem]'>
       <Table striped bordered hover className='table'>
      <thead className='text-xs'>
        <tr>
          <th>Taleb Tarihi</th>
          <th>Kişi AdSoyad</th>
          <th>Sosyal Media</th>
          <th>Sosyal M. Kullanıcı adı</th>
          <th>Takipçi Sayısı</th>
          <th>Paylaşımların İçeriği</th>
          <th>Etkinlik İli</th>
          <th>Etkinlik Tarihi</th>
          <th>İletişim Mobil</th>
        </tr>
      </thead>
      <tbody>
        {requestedEvents&&
        requestedEvents.map((item, index) => (
          <tr key={index}>
            <td>{format(new Date(item.created_at), 'dd/MM/yyyy')}</td>
            <td>{item.fullname}</td>
            <td>{item.social_media}</td>
            <td>{item.social_media_username}</td>
            <td>{item.number_of_followers}</td>
            <td className=''>{item.topic_of_posts}</td>
            <td>{item.event_province}</td>
            <td>{item.event_date}</td>
            <td>{item.mobile_number}</td>
            
          </tr>
        ))}
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td> <td></td> <td></td><td></td>
        </tr>
        <tr><td></td> <td></td> <td></td> <td></td> <td></td><td></td> <td></td> <td></td><td></td>
        </tr>
        
         
        
        
        {/* <tr><td>Tarkan ile Çay</td> <td>Saat: 12:00</td><td>Saat: 12:00</td></tr> */}
        {/* <tr><td>Tarkan ile Çay</td> <td>Saat: 12:00</td><td>Saat: 12:00</td></tr> */}

      </tbody>
    </Table>
    </div>
  )
}

export default RequestedEvents