import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { generalGetMethod, MESSAGES_URL } from '../api-calls/requests'

const AllUserMessages = () => {
const [userMessages, setuserMessages] = useState([])

const [allUsersMessages, setallUsersMessages] = useState([])

const navigateThere = useNavigate();

//fetch all users  messages
function fetchAllUsersMessages() {
    generalGetMethod(MESSAGES_URL).then(
        (response) => { 
            if (!response.status) {
                //dialog to say comment not fetched
            } else if(response.status===200){
                setallUsersMessages(response.data.messages)
            }
         }
    ).catch()
}


//fetch user related messages useeffect
  useEffect(() => {
    fetchAllUsersMessages()
  }, [])


  return (
    <div className='mt-[5rem]'>
        <h3 className='text-white'>Tüm mesajlar</h3>
        <div className='flex justify-center'>
            <div className='md:w-[50%] w-full space-y-3 '>

        {allUsersMessages.length>0&&
            allUsersMessages.map((message)=>(
                <div className='w-full  bg-blue-300 rounded-lg justify-center
                cursor-pointer flex hover:bg-blue-400'
                key={message.user_id}
                onClick={()=>{navigateThere(`/single-usermessages/${message.source_user_id}`)}}
                >
                    <div className='flex text-white gap-x-2 w-[40%]  
                    '>
                        <p>{'#'+message.source_user_id}</p>
                        <p>{message.nick}</p>
                    </div>
                </div>
            ))
        }
            </div>
        </div>

    </div>
  )
}

export default AllUserMessages