// import logo from '../../logo.svg';
import '../../App.css';

// import '@coreui/coreui/dist/css/coreui.min.css';
import { CContainer,CRow,CCol  } from '@coreui/react';

import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';




import logo from '../../logo.svg';

import Footer from '../footer';
import AdminEtkinlikTable from '../etkinlikler-table';


const Home = ()=>{

    return(

        <div className='homebody'>
        <div >

    <CContainer fluid >
       
        <h2  className='text-white'>Etkinlikler</h2>
        <AdminEtkinlikTable/>

        

    </CContainer>
    </div>
        
    <div>
    <Footer/>
    </div>
        </div>
    );

    
}

export default Home;