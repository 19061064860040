import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
// import { CREATE_COMMENT, GET_EVENT_RELATED_COMMENTS,generalGetMethos, generalPostMethod } from "../../components/requests";
import { Button} from "flowbite-react";
import { FloatingLabel, Form, FormGroup } from "react-bootstrap";
import { format } from "date-fns";
import { generalGetMethod, generalPostMethod, generalPostNotFormMethod, MESSAGES_URL } from "../api-calls/requests";



const SingleUserMessages = ()=>{
    const navigateThere = useNavigate();
    const useparam = useParams();
    const user_id = useparam.user_id ||0;

    const location = useLocation();
    const eventInfo = location.state || {};

    const [comments, setcomments] = useState([])

  const [comment, setcomment] = useState('');

  const event_id = eventInfo.event_id ||0;  

    //for creating comment
    function handleCreateComment(event) 
        {
            event.preventDefault();
            generalPostNotFormMethod(MESSAGES_URL,
                {'body':comment,'target_user_id':user_id}).then(
                (response) => { 
                    if (response.status===200) {
                        setcomment("")
                        //Handle making an alert that comment sent with dialog popup

                        //triger messages reload
                        fetchMessages();
                    } else {
                       //Handle making an alert that comment sent with dialog popup

                    }
                 }
            )
        }

    //fetch user related messages
    function fetchMessages() {
        generalGetMethod(MESSAGES_URL,{'source_user_id':user_id}).then(
            (response) => { 
                if (!response.status) {
                    //dialog to say comment not fetched
                } else if(response.status===200){
                    setcomments(response.data.messages)
                }
             }
        ).catch()
    }

    useEffect(()=>{fetchMessages()},[]);


    return(

    <div className="pt-[5rem]  px-3  ">
        <div className=" col-sm-6 mx-auto justify-content-center">
        <div className="row  flex-row justify-content-center">
            <div className="text-white mt-11">
                <h3>Site Admine Mesaj Yaz</h3>
            </div>
        <div className="row w-100 px-0">
        </div>


        <div className="col-8 mt-4 g-0">
                <Form onSubmit={(event) => { handleCreateComment(event) }}>
                    <FormGroup className="text-end">
                        <FloatingLabel
                            controlId="floatingTextarea"
                            label="Yorum"
                            className=""
                        >
                            <Form.Control as="textarea"
                            value={comment}
                            onChange={(event) => { setcomment(event.target.value) }}
                             placeholder="Leave a comment here" />
                        </FloatingLabel>

                        <Button type="submit" size="sm" className="mt-2 ml-auto bg-danger-subtle ">Gönder</Button>
                    </FormGroup>
                </Form>
            </div>
            {/* user comments */}

            <div className="overflow-y-auto h-[25rem] pt-9">
                <h5 className="text-white underline">Mesajlar</h5>
            {comments&&
                comments.map((comment) => (
                    <div key={comment.id}>
                        <p className="w-full mb-0 text-xs text-white text-end">
                            {format(new Date(comment.created_at),"dd-MM-yyyy HH:mm")}
                        </p>
                        <p className='feno-yorumlarim text-sm' >
                            {comment.body}
                        </p>
                    </div>

            ))}
            </div>
            </div>
            </div>
    </div>
    );
}

export default SingleUserMessages;