import React, { useState,useRef, useContext  } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import { withRouter, useNavigate } from "react-router-dom";
// import '@coreui/coreui/dist/css/coreui.min.css';

import "../App.css";
import MyFooter from '../components/footer';
// import { CContainer } from '@coreui/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AUTH_COOKIE_LINK, LOGIN_URL_PROD, withCredentialsValue } from '../components/api-calls/requests';

const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
// const LOGIN_URL_PROD = 'http://127.0.0.1:8000/api/login';  //For development




const LoginForm = () => { 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigateThere = useNavigate();

  // const { setAuth } = useContext(AuthContext);
    const userRef = useRef();
    const errRef = useRef();

    // const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage('')
    // Replace this part with your actual login logic
    // if (username === 'exampleuser' && password === 'examplepassword') {
    //   // Successful login logic (e.g., redirect to dashboard)
    //   console.log('Login successful!');
    // } else {
    //   setErrorMessage('Yanlış bilgileri girdiniz.');


   
    
    // if button enabled with JS hack
    // const v1 = USER_REGEX.test(name);
    // const v2 = PWD_REGEX.test(pwd);
    // if (!v1 || !v2) {
    //   console.log('There was an error');
    //     setErrMsg("Invalid Entry");
    //     return;
    // }
    try {

        axios.get(AUTH_COOKIE_LINK);

        const response = await axios.post(LOGIN_URL_PROD,
            JSON.stringify({ email,password }),
            {
                headers: { 'Content-Type': 'application/json',
              'Accept':'application/json',
             },
             withCredentials: withCredentialsValue
            }
        );

        if (response?.data.status==='success'){
          localStorage.setItem('isLoggedIn', JSON.stringify('true'));

          // console.log(response?.data.user)
          var parsedUser = JSON.parse(response.data.user)

          //This will be needed for the satin-al page
          localStorage.setItem('first_name', JSON.stringify(parsedUser.first_name));
          localStorage.setItem('last_name', JSON.stringify(parsedUser.last_name));
          localStorage.setItem('phone', JSON.stringify(parsedUser.phone));
          localStorage.setItem('user_type', JSON.stringify(parsedUser.user_type));
          if (parsedUser.user_type !='1') { //if user is not admin
            setErrorMessage('Admin olman gerekiyor');
          }else{
            navigateThere('/')}
          }
        console.log(response?.data.status);
        // console.log(response?.accessToken);
        // console.log(JSON.stringify(response))
        // setSuccess(true);
        // setErrorMessage('')
        //clear state and controlled inputs
        //need value attrib on inputs for this
        // setName('');
        // setPwd('');
        // setMatchPwd('');
    } catch (err) {
        if (!err?.response) {
          // console.log('There was an error');
          setErrorMessage('Başarısiz giriş');
        } else if (err.response?.status === 409) {
          const newLocal = 'There was an error';
          console.log(newLocal);
          setErrorMessage('Başarısiz giriş');
        } else {
          // console.log(JSON.stringify({ username,password}));
          setErrorMessage('Başarısiz giriş');
        }
        // errRef.current.focus();
    }

    
  };

  return (
    
    <div  className="login-page">
      {/* <CContainer> */}
        <h2 style={{color:'white'}}>Üye Giriş</h2> <br/>
    <Form onSubmit={handleLogin}>
      {errorMessage&&
        <p className='text-white bg-red-400'>{errorMessage}</p>
      }
      <Form.Group className="mb-3" >
        {/* <Form.Label>Kullanici Adı veya mail</Form.Label> */}
        <Form.Control type="email" id="username" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Mail adresi (örnek@gmail.com)" />
        {/* <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text> */}
      </Form.Group>

      <Form.Group className="mb-3" >
        {/* <Form.Label>Şifre</Form.Label> */}
        <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Şifre" />
      </Form.Group>
      {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Check me out" />
      </Form.Group> */}
      <Button variant="primary" type="submit">
        Giriş Yap
      </Button>
    </Form>
    {errorMessage && <p>{errorMessage}</p>}
    {/* </CContainer> */}
   

    </div>
    
    
    
   
  );
};

export default LoginForm;
