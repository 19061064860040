import LoginForm from "./giris";
import MyFooter from "../components/footer";
// import { CContainer } from "@coreui/react";
import 'bootstrap/dist/css/bootstrap.min.css';
// import '@coreui/coreui/dist/css/coreui.min.css';



const LoginPage = () =>{

    return(
        <div>
        <div >
        {/* <CContainer> */}
        <LoginForm/>       
        {/* </CContainer> */}
      
       
        </div>
        <MyFooter/>
        </div>
    );

    

}
export default LoginPage;