import { useState } from "react";
import { GET_USER_INSTAGRAM, disapproveAttendee, generalGetMethod } from './api-calls/requests';
import { useLocation } from "react-router-dom";
import { formatToTL } from "./helpers";


const FenomenKatilimciListItem = (props)=>{

    var user_id = '';
    var user_type ='';
    var first_name ='';
    var last_name ='';

    if (JSON.parse(localStorage.getItem('user_id'))) {  
        user_id = JSON.parse(localStorage.getItem('user_id'))
        user_type = JSON.parse(localStorage.getItem('user_type'))
        first_name = JSON.parse(localStorage.getItem('first_name'))
        last_name = JSON.parse(localStorage.getItem('last_name') )
       }

  const [errorMessage, seterrorMessage] = useState('');


    //disapprove an attendee
    function disApprove() {
        //clear previus error message if any
        seterrorMessage('')
        disapproveAttendee({"user_id":props.item.user_id,"event_id":props.event_id}).then(
            (response) => {
                if (!response.status) {
                    seterrorMessage("Başarsiz Redetme! Tekrar Deneyiniz")
                }else if (response.status===200) {
                    window.location.reload();
                }else{seterrorMessage("Başarsiz Redetme! Tekrar Deneyiniz")}
            }
        )
    }

    //open user instagram page
    function showInstagram(user_id)
    {
        generalGetMethod(GET_USER_INSTAGRAM+user_id).then((response) => { 
            if (!response.status) {
                //handle errors
            }else if(response.status===200)
            {
                //redirect to a new window
                window.open("https://www.instagram.com/"+response.data.user_instagram.replace("@",""), "_blank")
            }
         })
    }

// console.log(props.index)
    return(
        <div className={(props.red?" bg-red-400 ":"")+" px-0 row gap-0 border-bottom border-blue w-100"}>
            <div className="col-1 ">{props.index+1}</div> {/*array indexing starts from 0 so add 1*/}
            <div className={"col-4"}>
                {props.item.user_id != user_id ? props.item.first_name+' ...':
                props.item.first_name +' '+props.item.last_name
                }
            </div>
            <div className="col-3">{formatToTL(props.item.total_amount,'database') +' TL'}</div>
            <div className="col-3 flex items-center justify-around
            text-xs px-0">
            <p
                className=" cursor-pointer my-0 h-fit  bg-blue-300 w-[4rem] rounded-md"
                onClick={() => { showInstagram(props.item.user_id) }}
                >Kişi profile</p>

                <p
                className=" cursor-pointer my-0 h-fit bg-blue-300 w-[4rem] rounded-md"
                onClick={() => { disApprove() }}
                >Reddet</p>
            </div>

        </div>
    );
}

export default FenomenKatilimciListItem;