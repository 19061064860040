import axios from 'axios';

export const withCredentialsValue = true;


//base urls
const DEV_BASE_URL = 'http://127.0.0.1:8000/api';
const PROD_BASE_URL = 'https://api.buradago.com/public/api'


//uncomment the right line bellow based on whether you're deploying on 
//production or developemnt
const CURRENT_MODE_BASE_URL = PROD_BASE_URL;
const CURRENT_MODE_CSRF_BASE_URL = 'https://api.buradago.com/public';



//  export const CURRENT_MODE_BASE_URL = DEV_BASE_URL;
//  const CURRENT_MODE_CSRF_BASE_URL = 'http://127.0.0.1:8000';


//for auth
export const AUTH_COOKIE_LINK = CURRENT_MODE_CSRF_BASE_URL+'/sanctum/csrf-cookie';

export const LOGOUT_URL_PROD = CURRENT_MODE_BASE_URL+'/logout';  
export const LOGIN_URL_PROD = CURRENT_MODE_BASE_URL+'/login'; 
const ADD_EVENT_OWNER_URL = CURRENT_MODE_BASE_URL+'/add-event-owner';
export const ADD_ETKINLIK_URL = CURRENT_MODE_BASE_URL+'/add-event';
export const ALL_EVENTS_URL = CURRENT_MODE_BASE_URL+'/all-events'; 

//payments
export const PAYMENTS_TO_REVERSE = CURRENT_MODE_BASE_URL+'/payments/to-reverse';
export const REVERSED_PAYMENTS = CURRENT_MODE_BASE_URL+'/payments/reversed';
export const REVERSE = CURRENT_MODE_BASE_URL+'/payments/rforceventp';


export const APPROVE_ATTENDEE_URL = CURRENT_MODE_BASE_URL+'/eventowner-approve-attendee';  
export const DISAPPROVE_ATTENDEE_URL = CURRENT_MODE_BASE_URL+'/eventowner-disapprove-attendee';  
export const EVENT_OWNER_DISAPPROVED_LIST = CURRENT_MODE_BASE_URL+'/eventowner-disapproved-list?event_id=';  
export const GET_EVENT_ATTENDEES = CURRENT_MODE_BASE_URL+'/get-event-attendees-for-admin';
// Others
const GET_LEADER_BOARD_URL = CURRENT_MODE_BASE_URL+'/get-leader-board';
const GET_SINGLE_EVENT = CURRENT_MODE_BASE_URL+'/get-single-event-info';
export const GET_EVENT_RELATED_COMMENTS = CURRENT_MODE_BASE_URL+'/comments/event-related';
export const CREATE_COMMENT = CURRENT_MODE_BASE_URL+'/comments/create';
export const GET_USER_INSTAGRAM = CURRENT_MODE_BASE_URL+'/get-user-instagram?user_id=';

//events
export const REQUESTED_EVENTS_URL = CURRENT_MODE_BASE_URL+'/requestedevents';


//gateman
export const GET_GATEMAN_INIT_DATA_URL = CURRENT_MODE_BASE_URL+'/gateman/init/data';
export const ASSIGN_GATEMAN_URL = CURRENT_MODE_BASE_URL+'/gateman/assign';
export const DEACTIVATE_GATEMAN_URL = CURRENT_MODE_BASE_URL+'/gateman/deactivate';


//billings
export const ALL_BILLINGS = CURRENT_MODE_BASE_URL+'/billing/all-billings'; 
export const DOWNLOAD_INVOICE = CURRENT_MODE_BASE_URL+'/download-invoice?filename=';
export const BILLING_STATUS = CURRENT_MODE_BASE_URL+'/billing?action=status&status='
export const GET_BILLING_ADDRESS = CURRENT_MODE_BASE_URL+'/fetch-single-billing-address?id=';

//qr codes
export const GENERATE_EVENT_LINK_QRCODE = CURRENT_MODE_BASE_URL+'/event-linkqrcode?event_id=';
export const GENERATE_EVENTOWNER_LINK_QRCODE = CURRENT_MODE_BASE_URL+'/eventowner-linkqrcode?event_id=';

const url = axios.create({
	baseURL: CURRENT_MODE_BASE_URL,
});

//messages for creating and fetcting messages
export const MESSAGES_URL = CURRENT_MODE_BASE_URL+'/messages';


//Ably client credential. Note this credentials can only be used to listen on channels
//cant publish message
const ABLY_CHANNEL_API_KEY ='VH5AJA.xSbX3A:9qw0uPXVRPv5J5zYyEi3KHQEhmMHApMzM2l6P1lC8yI';
const ABLY_APP_NAME = "buradago";

//define function to create broadcast channel url using event id
export function createBroadCastUrl(event_id) {
	var ABLY_CHANNEL_URL ='https://realtime.ably.io/event-stream?channels='+ABLY_APP_NAME+event_id+'&v=1.2&key=' + ABLY_CHANNEL_API_KEY;
	return ABLY_CHANNEL_URL;
  }

export const getCategoriesAndFenomens = ()=>{

    let result = url
		.get('/fenomen-and-categories', {headers:{
			'Content-Type':'multipart-form/form-data',
			'Accept':'application/json'},
			withCredentials: withCredentialsValue})
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			return error;
		});

	return result;

}

export const addEventOwner = async (payload)=>{
	let result = await axios.post(ADD_EVENT_OWNER_URL,payload,
		{headers:{
			'Content-Type':'multipart-form/form-data',
			'Accept':'application/json'},
		 withCredentials: withCredentialsValue})
		.then((response)=>{
			// console.log('response in requests',response)
			return response;
		})
		.catch((error)=>{
			return error.response;
		});
	return result;
}

//Add event
export const addEvent = async (payload)=>{
	let result = await axios.post(ADD_ETKINLIK_URL,payload,
		{headers:{
			'Content-Type':'multipart-form/form-data',
			'Accept':'application/json'},
		 withCredentials: withCredentialsValue})
		.then((response)=>{
			// console.log('response in requests',response)
			return response;
		})
		.catch((error)=>{
			return error.response;
		});
	return result;
}

//create qrcodes
export const createQrs = async (payload)=>{
	let result = await axios.post(CURRENT_MODE_BASE_URL+"/generate-qrcodes",payload,
		{headers:{
			'Content-Type':'application/json',
			'Accept':'application/json'},
		 withCredentials: withCredentialsValue})
		.then((response)=>{
			console.log('response in requests',response)
			return response.data;
		})
		.catch((error)=>{
			return error;
		});
	return result;
}


//send qr codes
export const sendQrs = async (payload)=>{
	let result = await axios.post(CURRENT_MODE_BASE_URL+"/send_all_qrcodes",payload,
		{headers:{
			'Content-Type':'application/json',
			'Accept':'application/json'},
		 withCredentials: withCredentialsValue})
		.then((response)=>{
			// console.log('response in requests',response)
			return response.data;
		})
		.catch((error)=>{
			return error;
		});
	return result;
}

//disapprove attendee
export const disapproveAttendee = (payload)=>{

	const result = axios.post(DISAPPROVE_ATTENDEE_URL,payload,
		 {
			headers: { 'Content-Type': 'application/json',
		  'Accept':'application/json',
		  // 'Access-Control-Allow-Credentials':true
		 	},
			
			withCredentials: true})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error.response;
		});

	return result;
}

//approve attendee
export const approveAttendee = (payload)=>{

	const result = axios.post(APPROVE_ATTENDEE_URL,payload,
		
		{	
			headers: { 'Content-Type': 'application/json',
			'Accept':'application/json',
			// 'Access-Control-Allow-Credentials':true
			},
			
			withCredentials: true})
		.then((response) => {
			return response;
		})
		.catch((error) => {
			return error;
		});

	return result;

}

//get a single event info 
//also for display on single event page with eventID
export const getSingleEvent = (payload)=>{
    const result = axios.post(GET_SINGLE_EVENT,payload,{
        headers:{'Content-Type':'application/json',
        'Accept':'application/json'},
        withCredentials: withCredentialsValue
    }).then((response)=>{
        return response;
    }).catch((errors)=>{return errors;})
    
    return result;
}

//Artirmali satis / bidding orders
export const getLeaderBoard = (payload)=>{
    const result = axios.post(GET_LEADER_BOARD_URL,payload,{
        headers:{'Content-Type':'application/json',
                'Accept':'application/json'},
        withCredentials: withCredentialsValue
    }).then((response)=>{
        return response;
    }).catch((errors)=>{return errors;});
    return result;
}

//general post
export const generalPostMethod = (url, payload) => { 
	//create qrcodes
	let result = axios.post(url,payload,
		{headers:{
			'Content-Type':'multipart-form/form-data',
			'Accept':'application/json'},
		 withCredentials: withCredentialsValue})
		.then((response)=>{
			// console.log('response in requests',response)
			return response.data;
		})
		.catch((error)=>{
			return error.response;
		});
	return result;
 }

 //general post
export const generalGetMethod = (url, payload=null) => { 
	//create qrcodes
	let result = axios.get(url,
		{
			params:payload,
			
			headers:{
			'Content-Type':'application/json',
			'Accept':'application/json'},
		 withCredentials: withCredentialsValue})
		.then((response)=>{
			// console.log('response in requests',response)
			return response;
		})
		.catch((error)=>{
			return error.response;
		});
	return result;
 }

 //general post
export const generalPostNotFormMethod = (url, payload) => { 
	//create qrcodes
	let result = axios.post(url,payload,
		{headers:{
			'Content-Type':'application/json',
			'Accept':'application/json'},
		 withCredentials: withCredentialsValue})
		.then((response)=>{
			// console.log('response in requests',response)
			return response;
		})
		.catch((error)=>{
			return error.response;
		});
	return result;
 }