// import logo from '../logo.svg';

import { useContext, useState, useEffect } from "react";
import {Route,Switch,BrowserRouter, Routes, Outlet, useNavigate} from "react-router-dom";
import LoginProvider from "./components/context-provider";
import './App.css';
// import HeaderPc from './components/header-pc';
import Home from './components/pages/home-page';
// import HeaderMob from './components/header-mob';
import Header from './components/header';
// import Footer from './components/footer';
import Layout from "./components/layout";
import NoPage from "./pages/noPage";
import LoginPage from "./pages/login-page";


import UserAdminPage from "./components/pages/user-admin2";
// import ViewPdf from './components/view-pdf';
import FooterPage from "./pages/footer-element-page";
import FooterPage1 from "./pages/footer-element-page1";
import FooterPage2 from "./pages/footer-element-page2";
import EtkinlikOlusturPage from "./pages/etkinlik-olustur-page";
import AddEventOwner from "./components/etkinlik-kisi-ekleme";
import PaymentReversal from "./components/payment-reversal";
import ToreverseList from "./components/payment-to-reverse";
import UploadedInvoices from "./components/uploaded-invoices";
import AdminEtkinlikGor from "./components/admin-etkinlik-goruntuleme";
import AddEventGateman from "./components/add-event-gateman";
import EventAttendees from "./components/event-attendees";
import SingleUserMessages from "./components/messages/message-with-user";
import AllUserMessages from "./components/messages/all-user-messages";
import Seatings from "./components/seating/seating-test";
import RequestedEvents from "./components/events/requested-events";
import ReversedPayments from "./components/reversed-payments";
// import SingleUserMessages from "./components/messages/message-with-user";
// import FooterPage3 from "./pages/footer-element-page3";





function App() {

  const [isLoggedin, setIsLoggedin] = useState('false');


  
//Check if loggedin
// const navigateThere = useNavigate();
// if(loginVal==='true'){
// setIsLoggedin('true')}else{
//   navigateThere('/giris')
// };

  useEffect(()=>{
    let loginVal = JSON.parse(localStorage.getItem('isLoggedIn'));

  if(loginVal==='true'){
    setIsLoggedin('true')};  

  },[]);

  
  

  return (
    
    <div className="App">

    <BrowserRouter>
   
    
        <Routes>
          
        <Route path="/" element={
          // <LoginProvider value={{isLogin,setIsLogin}}>
        <Layout />
        // </LoginProvider>
        
        }>
        <Route index element={<Home />} />
        <Route path="/giris" element={<LoginPage />} />
        <Route path="/etkinlik-olustur-page" element={<EtkinlikOlusturPage />} />
        <Route path="/etkinlik-kisisi-ekleme" element={<AddEventOwner/>} />
        <Route path="/etkinlik-yetkili-kisisi-ekleme" element={<AddEventGateman/>} />
        <Route path="/user-admin" element={<UserAdminPage />} />
        <Route path="/payment-reversal" element={<PaymentReversal />} />                
        <Route path="/to-reverse" element={<ToreverseList />} />  
        <Route path="/refunded" element={<ReversedPayments />} />  
        <Route path="/uploaded-invoices" element={<UploadedInvoices />} />                
        <Route path="/etkinlik-detay" element={<AdminEtkinlikGor />} />  
        <Route path="/approved-ticket-buyers" element={<EventAttendees />} />  
        <Route path="/single-usermessages/:user_id" element={<SingleUserMessages />} />  
        <Route path="/all-usermessages" element={<AllUserMessages />} />
        <Route path="/requested-events" element={<RequestedEvents />} />
        

          {/*seat selector stuff  */}
        <Route path="/seats" element={<Seatings/>} />  
        
        
        {/* <Route path="/view-pdf" element={<ViewPdf />} /> */}
        {/* Site kullanim */}
        <Route path="/footer-element-page" element={<FooterPage />} /> 
        
        {/* KVKK */}
        <Route path="/footer-element-page1" element={<FooterPage1 />} />

        {/* CEREZ POLITIKASI */}
        <Route path="/footer-element-page2" element={<FooterPage2 />} />

        {/* "iletisim" */}
        {/* <Route path="/footer-element-page3" element={<FooterPage3 />} /> */}

       
        
        <Route path="*" element={<NoPage />} />
      </Route>
      
      </Routes>

      </BrowserRouter>
  
    </div>
    
  );
}

export default App;
